export default () => ({
    lastScrollTop: 0,
    delta: 5,
    height: 65,
    init() {
        this.height = this.$el.getBoundingClientRect().height
    },
    scroll: {
        ['@scroll.window.debounce']() {
            let scrollTop = window.scrollY
            this.height = this.$el.getBoundingClientRect().height

            if (Math.abs(this.lastScrollTop - scrollTop) <= this.delta) return

            if (scrollTop > this.lastScrollTop && scrollTop > this.height) {
                this.$el.style.top = '-' + this.$el.offsetHeight + 'px'
            } else {
                this.$el.style.top = 0
            }

            this.lastScrollTop = scrollTop
        }
    }
})